import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Alert } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "alert"
    }}>{`Alert`}</h1>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Alert>\n  I have <a href=\"https://urbansharing.com\">an anchor</a> inside me and some{\' \'}\n  <strong>bold</strong> text\n</Alert>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Alert mdxType="Alert">
    I have <a href="https://urbansharing.com">an anchor</a> inside me and some{" "}
    <strong>bold</strong> text
  </Alert>
    </Playground>
    <h2 {...{
      "id": "info"
    }}>{`Info`}</h2>
    <Playground __position={1} __code={'<Alert variant=\"info\">This is an info message</Alert>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Alert variant="info" mdxType="Alert">This is an info message</Alert>
    </Playground>
    <h2 {...{
      "id": "success"
    }}>{`Success`}</h2>
    <Playground __position={2} __code={'<Alert variant=\"success\">This is a success message</Alert>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Alert variant="success" mdxType="Alert">This is a success message</Alert>
    </Playground>
    <h2 {...{
      "id": "error"
    }}>{`Error`}</h2>
    <Playground __position={3} __code={'<Alert variant=\"error\">This is an error message</Alert>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Alert variant="error" mdxType="Alert">This is an error message</Alert>
    </Playground>
    <h2 {...{
      "id": "no-animation"
    }}>{`No animation`}</h2>
    <Playground __position={4} __code={'<Alert noAnimation>Static message without entry animation</Alert>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Alert noAnimation mdxType="Alert">Static message without entry animation</Alert>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Alert} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      